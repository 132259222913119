import {createSlice} from "@reduxjs/toolkit";
import billingReducer from "./billingSlice";
import deliveryReducer from "./deliverySlice";

const initialState = {
  vaultQuantity: 1,
  lidQuantity: 0,
  intermediateCeilingQuantity: 0,
  floorQuantity: 0
}

export const projectRootSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    reset: () => initialState,
    setVaultQuantity: (state, action) => Number.isInteger(action.payload)
      ? ({...state, vaultQuantity: action.payload})
      : state,
    setLidQuantity: (state, action) => Number.isInteger(action.payload)
      ? ({...state, lidQuantity: action.payload})
      : state,
    setIntermediateCeilingQuantity: (state, action) => Number.isInteger(action.payload)
      ? ({...state, intermediateCeilingQuantity: action.payload})
      : state,
    setFloorQuantity: (state, action) => Number.isInteger(action.payload)
      ? ({...state, floorQuantity: action.payload})
      : state
  }
});

export const {
  reset,
  setVaultQuantity,
  setLidQuantity,
  setIntermediateCeilingQuantity,
  setFloorQuantity,
} = projectRootSlice.actions;

export const projectReducer = (state = initialState, action) => {
  const rootState = projectRootSlice.reducer(state, action)
  const deliveryState = deliveryReducer(rootState.delivery, action)
  const billingState = billingReducer(rootState.billing, action)

  if (rootState.delivery === deliveryState && rootState.billing === billingState)
    return rootState;
  const {delivery, billing, ...others} = rootState;

  return {
    ...others,
    delivery: deliveryState,
    ...(billingState && {billing: billingState})
  };
}
