import React from 'react'

import {Grid, Button, Menu, MenuItem, Fab} from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {useDispatch, useSelector} from "react-redux"
import {useRouteMatch, useHistory} from "react-router-dom"

import {mannhartWebsite, orangeButtonStyle} from "../constants"
import imgSrcLogo from '../image/logo.png'
import imgSrcLogo2 from '../image/HelmMitWasserwaage.png'
import {authenticationActions} from "../actions"

const getInitials = user => {
  if (!user) {
    return "??"
  }
  const getInitial = s => {
    return s && s.length > 0 ? s[0].toUpperCase() : "?"
  }

  return getInitial(user.firstname) + getInitial(user.lastname)
}

const getName = user => {
  const fallback = "Benunutzername"
  if (!user) {
    return fallback
  }
  const firstName = user.firstname && user.firstname.length > 0 ? user.firstname : ''
  const lastName = user.lastname && user.lastname.length > 0 ? user.lastname : ''

  if (firstName.length < 1 && lastName.length < 1) {
    return fallback
  }
  let name = firstName

  if (name.length > 0) {
    name += ' '
  }
  name += lastName
  return name
}

const LoggedOutButtons = () => {
  const match = useRouteMatch()
  const step = match.params.step
  const history = useHistory()

  return (
    <React.Fragment>
      <Grid item>
        <Button
          sx={orangeButtonStyle}
          color="primary"
          variant="contained"
          onClick={() => history.push("/registration?navigateTo=/step/" + step)}
        >
          Registrieren
        </Button>
      </Grid>
      <Grid item>
        <Button
          sx={orangeButtonStyle}
          color="primary"
          variant="contained"
          onClick={() => history.push("/login?navigateTo=/step/" + step)}
        >
          Anmelden
        </Button>
      </Grid>
    </React.Fragment>
  )
}

const LoggedInButtons = () => {
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const initials = getInitials(user)
  const name = getName(user)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Grid item>
      <Grid container spacing={1} onClick={handleClick}>
        <Grid item>
          <Fab
            size="small"
            color="primary"
            aria-label="add"
            sx={orangeButtonStyle}
          >
            {initials}
          </Fab>
        </Grid>
        <Grid item>
          <KeyboardArrowDownIcon fontSize="large"/>
        </Grid>
      </Grid>
      <Menu
        id="simple-menu"
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <Grid
            container
            alignItems="center"
            onClick={handleClick}
          >
            <Grid item xs={3}>
              <Fab
                size="small"
                color="primary"
                aria-label="add"
                sx={orangeButtonStyle}
              >
                {initials}
              </Fab>
            </Grid>
            <Grid item xs={8}>
              {name}
            </Grid>
            <Grid item xs={1}>
              <KeyboardArrowUpIcon fontSize="large"/>
            </Grid>
          </Grid>
        </MenuItem>
        <hr/>
        <MenuItem
          onClick={() => {
            handleClose()
            history.push("/step/1")
          }}
        >
          Kabelschacht Konfigurator
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            history.push("/projects")
          }}
        >
          Bestellungen und Offertanfragen
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            history.push("/plans")
          }}
        >
          Persönliche Pläne
        </MenuItem>
        <hr/>
        <MenuItem onClick={() => {
          handleClose()
          dispatch(authenticationActions.logout())
          const currentUrl = window.location.href

          if (currentUrl.endsWith("plans") || currentUrl.endsWith("projects")) {
            history.push("/step/1")
          }
        }}>
          Abmelden
        </MenuItem>
      </Menu>
    </Grid>
  )
}

export const Header = () => {
  const loggedIn = useSelector(state => state.authentication.loggedIn)

  return (
    <Grid
      container
      spacing={1}
      justifyContent="space-between"
      alignItems="center"
      sx={{
        paddingTop: "2px",
        paddingRight: '1em',
      }}
    >
      <Grid item>
        <div style={{display: "flex"}}>
          <Button style={{padding: '0px'}} onClick={() => window.location = mannhartWebsite}>
            <img src={imgSrcLogo} style={{height: '75px'}} alt="MM Mannhart Logo"/>
          </Button>
          <Button stype={{padding: '0px', marginLeft: '0.5em'}} onClick={() => window.location = mannhartWebsite}>
            <img src={imgSrcLogo2} styel={{height: '75px'}} height={'75px'} alt="MM Mannhart Logo"/>
          </Button>
        </div>
      </Grid>
      <Grid
        container
        item
        direction="row-reverse"
        xs={4}
        alignItems="center"
        spacing={1}
      >
        {
          loggedIn
            ? <LoggedInButtons/>
            : <LoggedOutButtons/>
        }
      </Grid>
    </Grid>
  )
}
