export const isLoginRequired = authentication => {
  const now =  Math.ceil(new Date() / 1000)

  return !!authentication && !!authentication.jwtPayload && authentication.loggedIn && now + 300 > authentication.jwtPayload.exp
}

export const shouldExtendLogin = authentication => {
  if (isLoginRequired(authentication)) {
    return false
  }
  const now =  Math.ceil(new Date() / 1000)

  return authentication.jwtPayload && now + 1800 > authentication.jwtPayload.exp
}
